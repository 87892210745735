.container {
  max-width: none !important; /* max-width 제한 해제 */
}

.container .textarea {
  width: 200px;
}
.textarea {
  width: 1000px; /* 원하는 가로 크기로 설정 */
  height: 300px; /* 세로 크기 */
  padding: 10px;
  box-sizing: border-box; /* 패딩과 경계 포함 */
  margin: 10px auto; /* 중앙 정렬 */
  display: block;
}
