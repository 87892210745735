.add-button,
.update-button,
.delete-button,
.search-button,
.view-all-button,
.disabled-button {
  border-radius: 10px;
  padding: 5px 15px;
  color: #f5f5f5;
  font-weight: bold;
  font-family: Hahmlet;
  font-size: 17px;
  border: none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  margin: 10px;
}

.add-button {
  background-color: #e8b5b5;
}

.add-button:hover {
  background-color: #d49d9d;
}

.update-button {
  background-color: #e0e0e0;
  color: #444444;
}

.update-button:hover {
  background-color: #d4d3d3;
}

.view-all-button {
  background-color: #555555;
}

.view-all-button:hover {
  background-color: #444444;
}

.search-button {
  background-color: #f2d1d1;
  color: #645e5e;
}

.search-button:hover {
  background-color: #e6b8b8;
}

.delete-button {
  background-color: #ded4d4;
  color: #444444;
}

.delete-button:hover {
  background-color: #a9a7a1;
}

.confirm-button,
.cancel-button {
  border-radius: 10px;
  padding: 5px 15px;
  color: #f5f5f5;
  font-weight: bold;
  font-family: Hahmlet;
  font-size: 17px;
  border: none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  margin: 10px;
}

.confirm-button {
  background-color: #d3bcbc;
}

.confirm-button:hover {
  background-color: #c5a0a8;
}

.cancel-button {
  background-color: #a7a6a6;
}

.cancel-button:hover {
  background-color: #3f3e3e;
}

.button-disabled {
  border-radius: 0px;
  background-color: #b39297; /* disabled일 때의 색상 */
  cursor: default; /* 마우스 포인터를 not-allowed로 변경 */
}

.button-disabled:hover {
  background-color: #b39297; /* disabled일 때의 색상 */
  cursor: default; /* 마우스 포인터를 not-allowed로 변경 */
}

.button-abled {
  border-radius: 0px;
  background-color: #eacfd0;
  color: black;
}

.register-button {
  padding: 10px;
  margin-left: 10px;
  background-color: #4a4a4a;
  color: white;
  border: none;
  border-radius: 8px; /* 모든 버튼에 동일한 border-radius 적용 */
  cursor: pointer;
}

.register-button:hover {
  background-color: #333;
}

table {
  margin: 0 auto; /* 테이블을 가로로 중앙에 배치 */
  border-collapse: collapse; /* 테이블 셀 테두리 병합 */
}

th,
td {
  padding: 8px; /* 패딩 추가 */
  text-align: left; /* 텍스트 정렬 기본값 */
  border: 1px solid #ddd; /* 셀 테두리 스타일 */
}

#admin-header {
  color: #333;
  font-size: 3rem;
  text-align: center;
  display: block;
}
#admin-body {
  align-items: center;
  text-align: center; /* 모든 텍스트와 요소를 가운데 정렬 */
  padding: 20px;
}
#admin-header li a:hover {
  background-color: darkgray;
  color: #fff;
}

#statistics {
  width: 100%;
  list-style-type: none; /* 기본 불릿 제거 */
  padding: 0;
  margin: 0 auto; /* 가로로 중앙 정렬 */
  display: flex; /* 옆으로 정렬 플렉스 컨테이너로 설정 */
  flex-wrap: wrap;
  justify-content: center; /* 플렉스 항목들(메뉴 항목들)을 가운데 정렬 */
  border-radius: 8px;
}

#statistics li {
  width: 30%;
  margin-right: 10%;
  margin-bottom: 3%;
  background-color: #f0eeee;
}

/* 마지막 li 요소의 오른쪽 제거 */
#topmenu li:last-child {
  margin-right: 0;
}

/* Link 요소 */
a {
  text-decoration: none;
  color: black;
  /* font-size: 18px; */
  /* font-weight: bold; */
}

a:hover {
  /* background-color: #f4f4f4; */
}

body {
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
}

#topmenu {
  width: 100%;
  background-color: #f5e6e6;
  list-style-type: none; /* 기본 불릿 제거 */
  padding: 0;
  margin: 0 auto; /* 가로로 중앙 정렬 */
  display: flex; /* 옆으로 정렬 플렉스 컨테이너로 설정 */
  flex-wrap: wrap;
  justify-content: space-evenly; /* 메뉴 간의 간격을 균등하게 배치 */
  border-radius: 8px;
}

#topmenu li {
  flex-grow: 1; /* 각 li가 동일한 비율로 공간을 차지 */
  text-align: center; /* 텍스트를 중앙 정렬 */
}

#topmenu li a {
  padding: 10px 10px;
  display: block;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

#topmenu li a:hover {
  background-color: #c99ba6;
  color: #fff;
}

/* 활성화된 링크에 적용할 스타일 */
#topmenu li a.active-link {
  background-color: #b39297;
  color: #fff;
  font-weight: bold; /* 강조 표시 */
}

#pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

#pagination button {
  background-color: #df919e;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  margin: 0 10px;
}

#pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

#pagination span {
  margin: 0 10px;
  font-size: 16px;
  font-weight: bold;
}

.adminTable {
  width: 70%;
  font-size: 17px;
}

.adminTable th {
  font-weight: bold;
  text-align: center;
  background-color: #f0f0f0; /* 헤더 배경색 */
  padding: 10px;
}

.adminTable td {
  font-weight: normal;
  text-align: left;
  padding: 10px;
}


.adminShowList {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
  text-align: center;
  width: 150px;
  height: 200px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.adminShowList img {
  width: 80%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
}

.adminShowList :hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

#connected-product {
  color: #D5006D;
  cursor: pointer;
  transition: font-size 0.1s ease-in-out; 
}

#connected-product:hover {
  font-size: 1.2em; /* 원하는 크기로 조절하세요 */
}
