h2 {
  font-size: 28px;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formGroup {
  width: 100%;
  max-width: 500px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.formGroup.inline {
  display: flex;
  align-items: center; /* input과 %를 세로로 맞춤 */
}

label {
  font-size: 16px;
  margin-bottom: 5px;
  color: #333;
}

input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"],
select,
/* input[type="select"], */
input[type="date"] {
  width: 96.5%;
  padding: 12px;
  border: 1px solid #d1d9e6;
  font-size: 16px;
  border-radius: 8px;
  color: #333;
  transition: border-color 0.3s ease;
  background-color: transparent;
}

/* 비활성화된 상태에서의 스타일 */
input[type="text"]:disabled,
input[type="email"]:disabled {
  /* background-color: #f0f0f0; */
  /* color: #999; */
  border-color: #ccc;
  cursor: not-allowed;
  opacity: 0.6;
}

input[type="text"]:focus,
input[type="number"]:focus,
input[type="password"]:focus,
input[type="date"]:focus {
  border-color: #a4c0e4;
  outline: none;
}

.inline input[type="number"] {
  width: calc(100% - 50px); /* 퍼센트 기호 공간을 확보 */
}

span {
  font-size: 16px;
  margin-left: 5px; /* % 기호와 input 사이에 여백 */
}
