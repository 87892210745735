.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.category {
  font-size: 0.9rem;
  color: #888;
  margin-bottom: 5px;
  font-weight: bold;
}

.title {
  font-size: 1.8rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.date {
  font-size: 0.8rem;
  color: #666;
  margin-bottom: 15px;
}

.contents {
  font-size: 1rem;
  line-height: 1.6;
  color: #333;
  white-space: pre-wrap; /* 줄바꿈을 유지하면서 내용 표시 */
}

.hr {
  border: none;
  border-top: 1px solid #ddd;
  margin: 20px 0;
}
