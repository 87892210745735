.support-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 10px 20px;
  background-color: #df919e;
  color: #f5f5f5;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  z-index: 1000;
  transition: background-color 0.3s ease;
}

.support-button:hover {
  background-color: #c7727e;
}

.support-modal {
  position: fixed;
  bottom: 70px;
  right: 20px;
  background-color: white;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  z-index: 1000;
}

.support-modal-content {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.support-modal-option {
  padding: 10px;
  background-color: #c9aaaf;
  border: none;
  color: #f5f5f5;
  text-align: left;
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
  margin: 5px 0;
  transition: background-color 0.3s ease;
}

.support-modal-option:hover {
  background-color: #b39297;
}
