/* CSS */
.loaderOverlay {
  position: fixed; /* 화면에 고정 */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1); /* 반투명 배경 */
  backdrop-filter: blur(10px); /* 배경 블러 효과 */
  z-index: 9999; /* 다른 요소들보다 위에 표시 */
  display: flex; /* 자식 요소 중앙 정렬 */
  align-items: center;
  justify-content: center;
}

.loader {
  width: 50px;
  height: 50px;
  position: relative;
  z-index: 1;
  transform: translateX(-50%);
}

.loader::before,
.loader::after {
  content: "";
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  mix-blend-mode: multiply;
  animation: rotate92523 2s infinite cubic-bezier(0.77, 0, 0.175, 1);
}

.loader::before {
  background-color: #75e2ff;
}

.loader::after {
  background-color: #ff8496;
  animation-delay: 1s;
}

@keyframes rotate92523 {
  0%,
  100% {
    left: 35px;
  }

  25% {
    transform: scale(0.3);
  }

  50% {
    left: 0%;
  }

  75% {
    transform: scale(1);
  }
}
