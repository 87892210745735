/* UserRegister.module.css */
.body {
  text-align: center;
}

.container {
  max-width: 600px;
  margin: 40px auto;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  padding: 30px;
  box-sizing: border-box;
}

.id_input,
.zipcode_input {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.id_input input,
.zipcode_input input {
  flex: 1;
  margin-right: 10px; /* 입력 필드와 버튼 간 간격 */
}

.id_input [type="button"],
.zipcode_input [type="button"] {
  flex: 0;
  margin-left: 10px;
  border-radius: 4px;
}

.pwd_input input {
  margin-bottom: 10px; /* 입력 필드 사이에 간격을 줌 */
}

.id_input [type="button"] {
  margin-top: -3px !important;
  margin-left: 5px !important;
  margin-right: 5px;
  border-radius: 4px;
}

.email_input {
  display: flex;
  align-items: center;
}

.email_input input {
  padding: 10px;
  /* border: 1px solid #ccc;
  border-radius: 4px; */
  margin-right: 8px;
  width: 50%;
}
input[type="button"] {
  margin-left: 3px;
  border-radius: 4px;
}

.email_input span {
  margin: 0 8px;
}

.email_input select {
  padding: 10px;

  width: 150px;
}

/* 우편번호와 검색 버튼을 같은 줄에 정렬 */

.zipcode_input input[type="button"] {
  margin-bottom: 22px;
}

.error_message {
  text-align: left;
  color: #d9534f;
  font-size: 14px;
  /* margin-top: 5px; */
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.loading div {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.modal {
  /* 모달 창의 내용 부분 */
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 500px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  outline: none; /* 포커스 시 기본 윤곽선 제거 */
  text-align: center;
}

.modalContent {
  background-color: rgba(255, 255, 255, 0.8); /* 투명도 조절 */
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
  margin: 0 auto;
}

.modalOverlay {
  background-color: rgba(0, 0, 0, 0.5); /* 배경을 어둡게 하면서 반투명으로 */
}
