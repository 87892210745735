.container {
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.sortOptions {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.sortSelect {
  width: 150px;
  margin-left: 10px;
  padding: 5px;
  border: 1px solid #df919e;
  border-radius: 5px;
}

.productList {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  text-align: center;
}

.productItem {
  position: relative;
  border: 1px solid #ddd;
  padding: 10px;
  margin: 10px;
  background-color: #fff;
  border-radius: 5px;
  transition: box-shadow 0.3s;
  overflow: hidden; /* 이미지가 확대될 때 밖으로 튀어나가지 않게 */
}

.productItem:hover {
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

/* 품절된 상품에 불투명한 흰색 덮어씌우기 */
.soldOutOverlay {
  opacity: 0.6;
  background-color: rgba(255, 255, 255, 0.7);
  position: relative;
}

/* 품절된 상품 메시지 */
.soldOutMessage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: red;
  font-size: 18px;
  font-weight: bold;
  z-index: 10;
}

.productImage3 img {
  width: 100%; /* 이미지가 컨테이너의 전체 너비를 차지하도록 설정 */
  height: 200px; /* 모든 이미지의 높이를 고정 */
  /* width: 200px; */
  object-fit: cover; /*이미지의 비율을 유지하면서 자르지 않고 전체를 표시*/
  border-radius: 8px;
  display: block;
  transition: transform 0.3s ease;
}

.productItem:hover .productImage3 img {
  transform: scale(1.1); /* 이미지가 살짝 확대되도록 설정 */
}

.productName {
  font-weight: bold;
  font-size: 16px;
  margin-top: 10px;
  color: #333;
}

.productPrice {
  font-size: 14px;
  color: #999;
  margin-top: 5px;
}

.productCategory {
  font-size: 12px;
  color: #666;
  margin-top: 5px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.categoryLinks {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.categoryLink {
  font-weight: bold;
  color: #df919e;
  text-decoration: none;
  padding: 10px;
  border-radius: 5px;
  background-color: #f0f0f0;
  border: none;
}

.categoryLink:hover {
  background-color: #e0e0e0;
}

/* 활성화된 카테고리 스타일 */
.activeCategoryLink {
  background-color: #df919e;
  color: white;
  font-weight: bold;
}

.pagination button {
  background-color: #d0bbbe;
  color: white;
  /* background: none; */
  border: 1px solid #d0bbbe;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin: 0 5px;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.pagination span {
  margin: 0 10px;
  font-size: 14px;
}
