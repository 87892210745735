.scrapContainer {
  max-width: 1200px;
  margin: 0px auto;
  /* padding: 20px; */
}

.title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 30px;
}

.scrapGrid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px; /* 카드 사이 간격 */
}

.scrapItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.scrapItem:hover {
  transform: scale(1.05); /* 마우스 호버 시 확대 */
}

.scrapImage {
  width: 100%; /* 그리드 아이템 안에서 이미지를 꽉 채움 */
  height: 250px;
  object-fit: cover;
  border-radius: 10px;
  margin-bottom: 15px;
}

.scrapLink {
  text-decoration: none;
  color: #333;
  font-weight: bold;
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 10px;
}

.emptyMessage {
  text-align: center;
  font-size: 1.2rem;
  color: #888;
  padding: 50px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.paginationButton {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.3s ease;
}

.paginationButton:disabled {
  background-color: #dbdbdb;
  cursor: not-allowed;
}

.paginationText {
  font-size: 1rem;
  color: #333;
}
