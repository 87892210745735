/* 뷰 모드 버튼을 담는 컨테이너 */
.view-mode-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px; /* 버튼과 게시물 리스트 사이의 간격 */
}

/* 페이징 */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  background-color: #d0bbbe;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #c9aaaf;
}

.pagination button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.pagination span {
  font-size: 1rem;
  color: #333;
}

/* 전체 게시물 리스트를 담는 컨테이너 */
.post-list-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  padding: 20px;
  margin-top: 10px; /* 게시물 리스트와 페이지 상단 간의 간격 */
}

/* 각각의 게시물 카드 */
.post-card {
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  display: flex;
  flex-direction: column;
  break-inside: avoid; /* 그리드에서 흐트러짐 방지 */
  height: auto; /* 카드 높이를 자동으로 설정 */
  min-height: 100px; /* 최소 높이 설정 */
  position: relative;
}

/* 마우스 오버 시 카드 애니메이션 효과 */
.post-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
}

/* 게시물 이미지 스타일 */
.post-image {
  width: 100%;
  height: 150px;
  object-fit: cover; /* 이미지가 컨테이너를 채우도록 설정 */
  display: block;
}

/* 게시물 내용 섹션 */
.post-content {
  padding: 10px;
  flex-grow: 1;
}

/* 닉네임 또는 유저 정보 섹션 */
.post-nickname {
  padding: 8px 10px;
  background-color: #f9f9f9;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
}

/* 닉네임 링크 스타일 */
.post-nickname a {
  color: #333;
  text-decoration: none;
  transition: color 0.2s ease;
}

/* 닉네임 링크 마우스 오버 효과 */
.post-nickname a:hover {
  color: #0073e6;
}

/* 게시물이 없을 때 표시할 메시지 */
.empty-message {
  text-align: center;
  grid-column: span 3;
  padding: 50px;
  color: #888;
  font-size: 16px;
}
