/* 공통 스타일 */
.body {
  text-align: center;
}

.login-form input[type="text"],
.login-form input[type="password"] {
  width: 100%; /* 동일한 너비 설정 */
  padding: 10px; /* 동일한 패딩 설정 */
  margin-bottom: 20px; /* 동일한 아래 여백 */
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  box-sizing: border-box; /* 패딩과 보더 포함한 너비 설정 */
  font-size: 14px; /* 동일한 폰트 크기 설정 */
}
.login-form input[type="submit"] {
  background-color: #df919e; /* 버튼 배경색 */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px; /* 동일한 패딩 */
  font-size: 14px; /* 버튼 폰트 크기 */
  width: 100%; /* 입력 필드와 동일한 너비 */
  box-sizing: border-box; /* 패딩과 보더 포함한 너비 설정 */
  cursor: pointer;
  text-align: center; /* 텍스트 가운데 정렬 */
  display: block; /* 블록 요소로 설정하여 너비 100% 적용 */
  margin: 0 auto; /* 가운데 정렬 */
  margin-bottom: 20px; /* 아래 여백 */
}

.login-form input[type="submit"]:hover {
  background-color: #c7727e; /* 버튼 hover 색상 */
}

/* 컨테이너 스타일 */
.login-container {
  max-width: 400px;
  margin: 80px auto;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* 에러 메시지 */
.errorMessage {
  text-align: left !important;
  color: #d9534f !important;
  font-size: 14px !important;
}

/* 하단 링크 스타일 */
.signup-link {
  text-align: center;
  margin-top: 20px;
}

.login-container a {
  color: #df919e;
  text-decoration: none;
}

.login-container a:hover {
  color: #c7727e;
}
