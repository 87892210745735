@import url("https://fonts.googleapis.com/css2?family=Hahmlet:wght@100..900&family=Noto+Serif+KR:wght@200..900&display=swap");

.hahmlet {
  font-family: "Hahmlet", serif;
  font-optical-sizing: auto;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* 전체 화면을 채우는 최소 높이 설정 */
}

/* 본문 컨텐츠 */
.main-content {
  flex: 1; /* 남은 공간을 차지하도록 설정 */
}

/* 푸터 */
.footer-container {
  color: #444; /* 더 중립적인 컬러 */
  padding: 20px 15px; /* 여백 줄임 */
  font-family: "Hahmlet";
  font-size: 50%;
  text-align: center;
  width: 100%; /* 푸터가 화면 너비 전체에 걸쳐 나타나도록 설정 */
  margin-top: auto; /* 본문 아래에 푸터가 위치하도록 설정 */
}

.footer-logo {
  font-size: 14px; /* 로고 크기 줄임 */
  font-weight: bold;
  letter-spacing: 1px; /* 글자 간격 줄임 */
  text-transform: uppercase;
}

.social-icons a {
  color: #444; /* 더 중립적인 색상 */
  font-size: 20px; /* 소셜 아이콘 크기 줄임 */
  margin: 0 10px; /* 여백 줄임 */
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #888; /* 덜 강조된 호버 색상 */
}

.footer-bottom {
  font-size: 12px; /* 폰트 크기 줄임 */
}

.footer-bottom p {
  margin: 3px 0; /* 여백 줄임 */
}

.footer-bottom a {
  color: #888; /* 중립적인 색상 */
  text-decoration: none;
}

.footer-bottom a:hover {
  text-decoration: underline;
}
