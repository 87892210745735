.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.orderInfo {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-size: 1.1rem;
  line-height: 1.5;
  color: #333;
}

.orderInfo span {
  font-weight: bold;
  color: #444444;
}

.productList {
  margin-bottom: 30px;
}

.productItem {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr; /* 상품명, 수량, 가격, 리뷰 상태/버튼 */
  align-items: center; /* 모든 항목을 수직 가운데 정렬 */
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  margin-bottom: 15px;
}

.productName {
  font-weight: bold;
  font-size: 1.1rem;
  color: #c7727e;
  transition: font-size 0.3s ease; /* 크기 변화에 애니메이션을 적용하여 부드럽게 변경되도록 설정 */
}

.productName:hover {
  font-size: 1.11rem; /* hover 시에 폰트 크기를 조금 더 크게 설정 */
}

.productQuantity {
  font-size: 1rem;
  color: #666;
}

.productPrice {
  font-size: 1.2rem;
  font-weight: bold;
  color: #d9534f;
}

.reviewButton {
  background-color: #df919e;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.reviewButton:hover {
  background-color: #c7727e;
}

.userInfo {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.userInfo h3 {
  font-size: 1.3rem;
  margin-bottom: 15px;
  color: #333;
}

.userInfo p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #555;
}

.userInfo span {
  font-weight: bold;
}

.btnDisabled {
  background-color: #ddd; /* 비활성화 시 회색 배경 */
  color: #888; /* 비활성화 시 텍스트 색상 */
  cursor: not-allowed; /* 비활성화 시 커서 */
}
.btnDisabled:hover {
  background-color: #ddd; /* hover 시에도 동일한 색상 유지 */
  color: #888; /* hover 시에도 동일한 텍스트 색상 유지 */
  cursor: not-allowed; /* 커서 상태 유지 */
}
