.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

/* 페이징 */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  background-color: #d0bbbe;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  font-family: "Hahmlet";
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #c9aaaf;
}

.pagination button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.pagination span {
  font-size: 1rem;
  color: #333;
}

.noticeItem {
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.noticeItem:last-child {
  border-bottom: none;
}

.noticeTitle {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  text-decoration: none;
}

.noticeTitle:hover {
  color: #c7727e;
}

.noticeCategory {
  font-size: 0.9rem;
  color: #888;
  margin-bottom: 5px;
}

.noticeDate {
  font-size: 0.8rem;
  color: #666;
  margin-top: 5px;
}
