.container {
  max-width: 800px;
  margin: 40px auto;
  padding: 20px;
  background-color: #fff;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
}

.label {
  font-weight: bold;
  margin-right: 10px;
  color: #333;
}

.value {
  font-size: 16px;
  color: #555;
}

.price {
  font-size: 20px;
  font-weight: bold;
  color: #d9534f;
  margin-bottom: 20px;
}

.discountRate {
  font-size: 16px;
  color: #5cb85c;
  margin-left: 10px;
}

.productDescription {
  margin: 20px 0;
  line-height: 1.6;
  color: #555;
}

.productCategory {
  font-size: 16px;
  color: #007bff;
  margin-bottom: 20px;
}

.productImage {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
  border-radius: 8px;
}

.reviewSection {
  margin-top: 30px;
  text-align: center; /* 중앙 정렬 */
}

.reviewSection h3 {
  margin-bottom: 20px;
  color: #333;
}

.reviewGrid {
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(300px, 1fr)
  ); /* 300px 크기의 칸을 자동으로 채움 */
  grid-gap: 20px; /* 칸 사이에 여백 */
  justify-items: center; /* 그리드 아이템들을 중앙 정렬 */
  margin-top: 20px;
}

.reviewItem {
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
  height: 100%; /* 동일한 높이를 유지 */
  display: flex;
  flex-direction: column; /* 내용물이 상하로 배치되도록 설정 */
  justify-content: space-between; /* 상하로 간격을 균등하게 조정 */
}

.reviewItem h4 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #333;
}

.reviewItem p {
  flex-grow: 1; /* 본문이 중간 영역을 채우도록 설정 */
  margin-bottom: 20px;
  color: #555;
}

.reviewItem .rating {
  color: #df919e;
  font-size: 16px;
  font-weight: bold;
}

.communityLink {
  display: inline-block;
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.communityLink:hover {
  background-color: #0056b3;
}

.productImage2 {
  max-width: 70%;
  max-height: 400px;
  object-fit: cover; /* 비율을 유지하면서 크기에 맞게 자름 */
  border-radius: 8px; /* 모서리 둥글게 */
  display: block; /* 이미지 블록 요소로 처리 */
  margin: 0 auto; /* 수평 가운데 정렬 */
}
