.reviewCard {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex; /* 이미지와 텍스트를 가로로 나란히 배치 */
  align-items: center;
  gap: 20px; /* 이미지와 텍스트 사이에 여백 추가 */
  max-width: 600px; /* 리뷰 박스의 최대 너비를 600px로 제한 */
  margin-left: auto; /* 박스를 가운데 정렬 */
  margin-right: auto; /* 박스를 가운데 정렬 */
}

.reviewCard img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 5px;
  transition: transform 0.3s ease; /* 호버 시 부드러운 변화를 위해 트랜지션 추가 */
}

.reviewCard img:hover {
  transform: scale(1.1); /* 호버 시 이미지 확대 */
  opacity: 0.8; /* 호버 시 이미지 투명도 조절 */
}

.reviewInfo {
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1; /* 텍스트가 이미지 옆에 가득 차도록 설정 */
}

.reviewTitle {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}

.reviewContent {
  color: #666;
  line-height: 1.6;
}

.productName {
  font-size: 1.1rem; /* 적당한 크기로 상품명을 강조 */
  font-weight: bold;
  color: #333; /* 다크 그레이 색상으로 깔끔한 텍스트 */
  margin-bottom: 2px; /* 상품명과 별점 사이에 간격 추가 */
  text-align: left; /* 상품명을 왼쪽 정렬 */
}

.buttonContainer {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 10px;
}

.editButton {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.deleteButton {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.editButton:hover {
  background-color: #45a049;
}

.deleteButton:hover {
  background-color: #e53935;
}

.paginationButton {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.paginationButton:hover {
  background-color: #0056b3;
}

.paginationContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 10px;
}
