.followContainer {
  background-color: #f4f4f9;
  border-radius: 10px;
  padding: 20px;
  max-width: 500px;
  margin: 0 auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.followContainer button {
  width: 150px;
}

.followItem {
  display: flex;
  align-items: center; /* 세로 정렬 고정 */
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
  width: 100%;
}

.followItem:last-child {
  border-bottom: none;
}

.profilePic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
  flex-shrink: 0; /* 프로필 이미지 크기 고정 */
}

.profileLink {
  font-size: 1.2em;
  color: #333;
  text-decoration: none;
  margin-right: 10px;
  /* 링크의 최대 너비 설정, 긴 텍스트는 줄임표로 처리 */
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 1;
}

.profileLink:hover {
  color: #007bff;
  text-decoration: underline;
}

.followButton,
.unfollowButton,
.itsMeButton {
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  /* 버튼의 고정된 크기 */
  min-width: 130px;
  max-width: 130px;
  text-align: center;
  flex-shrink: 0; /* 버튼 크기 고정 */
}

.followButton {
  background-color: #007bff;
  color: white;
}

.followButton:hover {
  background-color: #0056b3;
}

.unfollowButton {
  background-color: #ff4757;
  color: white;
}

.unfollowButton:hover {
  background-color: #e02424;
}

.itsMeButton {
  background-color: #6c757d;
  color: white;
}

.itsMeButton:hover {
  background-color: #5a6268;
}
