/* PromotionAdvertise.module.css */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 28px;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
}

.inputContainer {
  width: 100%;
  max-width: 700px;
}

.textarea {
  width: 100%;
  height: 100px;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #d1d9e6;
  background-color: #ffffff;
  font-size: 16px;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.textarea:focus {
  border-color: #a4c0e4;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  outline: none;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px; /* 간격을 좁게 설정 */
  position: relative; /* dropdown 위치 조정을 위한 relative 설정 */
}

.select {
  width: 100%;
  padding: 12px;
  border: 1px solid #d1d9e6;
  border-radius: 8px;
  background-color: #ffffff;
  font-size: 16px;
  color: #333;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='8' viewBox='0 0 12 8'%3E%3Cpath fill='%23333' d='M1.41 0L6 4.58 10.59 0 12 1.41l-6 6-6-6z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 12px 8px;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.select:focus {
  border-color: #a4c0e4;
  outline: none;
}

.input {
  width: 96%;
  padding: 12px;
  border: 1px solid #d1d9e6;
  border-radius: 8px;
  background-color: #ffffff;
  font-size: 16px;
  color: #333;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.input:focus {
  border-color: #a4c0e4;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  outline: none;
}

.dropdown {
  position: absolute;
  top: 100%; /* 입력 필드 바로 아래에 위치하도록 설정 */
  left: 0;
  width: 100%;
  max-width: 100%; /* 부모 요소에 맞춰 확장 */
  background-color: white;
  border: 1px solid #d1d9e6;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 10;
  border-radius: 8px;
  overflow: hidden;
  max-height: 200px; /* 5개의 항목을 표시하기 위한 최대 높이 */
  overflow-y: auto; /* 스크롤 가능하게 설정 */
}

.dropdownItem {
  padding: 12px 15px;
  cursor: pointer;
  background-color: #ffffff;
  transition: background-color 0.3s ease;
  font-size: 16px;
  color: #333;
}

.dropdownItem:hover {
  background-color: #f5f5f5;
}
