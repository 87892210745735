@import url("https://fonts.googleapis.com/css2?family=Hahmlet:wght@100..900&family=Noto+Serif+KR:wght@200..900&display=swap");

body {
  font-family: "Hahmlet";
}

::placeholder {
  font-family: "Hahmlet";
}

.hahmlet {
  font-family: "Hahmlet", serif;
  font-optical-sizing: auto;
}
.btn1 {
  border-radius: 10px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #c9aaaf;
  color: #f5f5f5;
  font-weight: border;
  font-family: "Hahmlet";
  font-size: 17px;
  border: none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  margin: 10px; /* 상하좌우 모두 20px의 여백 */
}

.btn1:hover {
  background-color: #b39297;
}

.btn1Small {
  border-radius: 10px;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: #c9aaaf;
  color: #f5f5f5;
  font-weight: border;
  font-family: "Hahmlet";
  font-size: 12px;
  border: none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  margin: 10px; /* 상하좌우 모두 20px의 여백 */
}

.btn1Small:hover {
  background-color: #b39297;
}

.btn1Long {
  border-radius: 10px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #c9aaaf;
  color: #f5f5f5;
  font-weight: border;
  font-family: "Hahmlet";
  font-size: 17px;
  width: 100%;
  border: none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  margin: 10px; /* 상하좌우 모두 20px의 여백 */
}

.btn1Long:hover {
  background-color: #b39297;
}

.btn2 {
  border-radius: 10px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #df919e;
  color: #f5f5f5;
  font-weight: border;
  font-family: "Hahmlet";
  font-size: 17px;
  border: none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  margin: 10px; /* 상하좌우 모두 20px의 여백 */
}

.btn2:hover {
  background-color: #c7727e;
}

.btn2Small {
  border-radius: 10px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #df919e;
  color: #f5f5f5;
  font-weight: border;
  font-family: "Hahmlet";
  font-size: 12px;
  border: none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  margin: 10px; /* 상하좌우 모두 20px의 여백 */
}

.btn2Small:hover {
  background-color: #c7727e;
}

.btn2Long {
  border-radius: 10px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #df919e;
  color: #f5f5f5;
  font-weight: border;
  font-family: "Hahmlet";
  font-size: 17px;
  width: 100%;
  border: none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  margin: 10px; /* 상하좌우 모두 20px의 여백 */
}

.btn2Long:hover {
  background-color: #c7727e;
}

.btn3 {
  border-radius: 10px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #c6c3bd;
  color: #f5f5f5;
  font-weight: border;
  font-family: "Hahmlet";
  font-size: 17px;
  border: none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  margin: 10px; /* 상하좌우 모두 20px의 여백 */
}

.btn3:hover {
  background-color: #a8a5a0;
}

.btn3Small {
  border-radius: 10px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #c6c3bd;
  color: #f5f5f5;
  font-weight: border;
  font-family: "Hahmlet";
  font-size: 12px;
  border: none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  margin: 10px; /* 상하좌우 모두 20px의 여백 */
}

.btn3Small:hover {
  background-color: #a8a5a0;
}

.btn3Long {
  border-radius: 10px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #c6c3bd;
  color: #f5f5f5;
  font-weight: border;
  width: 100%;
  font-family: "Hahmlet";
  font-size: 17px;
  border: none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  margin: 10px; /* 상하좌우 모두 20px의 여백 */
}

.btn3Long:hover {
  background-color: #a8a5a0;
}

.btn4 {
  border-radius: 10px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #444444;
  color: #f5f5f5;
  font-weight: border;
  font-family: "Hahmlet";
  font-size: 17px;
  border: none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  margin: 10px; /* 상하좌우 모두 20px의 여백 */
}

.btn4:hover {
  background-color: #000000;
}

.btn4Small {
  border-radius: 10px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #444444;
  color: #f5f5f5;
  font-weight: border;
  font-family: "Hahmlet";
  font-size: 12px;
  border: none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  margin: 10px; /* 상하좌우 모두 20px의 여백 */
}

.btn4Small:hover {
  background-color: #000000;
}

.btn4Long {
  border-radius: 10px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #444444;
  color: #f5f5f5;
  font-weight: border;
  width: 100%;
  font-family: "Hahmlet";
  font-size: 17px;
  border: none;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  margin: 10px; /* 상하좌우 모두 20px의 여백 */
}

.btn4Long:hover {
  background-color: #000000;
}

/* 페이징 */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  background-color: #d0bbbe;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  font-family: "Hahmlet";
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #c9aaaf;
}

.pagination button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.pagination span {
  font-size: 1rem;
  color: #333;
}
