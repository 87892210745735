/* 기본 체크박스를 숨기는 스타일 */
.input {
  position: absolute;
  opacity: 0; /* 기본 체크박스 숨김 */
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
}

/* 체크박스 스타일 */
.cbx {
  position: relative;
  width: 15px;
  height: 15px;
  border: 1px solid #df919e; /* 라디오 버튼과 동일한 테두리 색상 */
  border-radius: 5px;
  transition: background 0.2s ease;
  cursor: pointer;
  display: block;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  top: 8.2px;
  
}

.cbx:after {
  content: "";
  position: absolute;
  top: 2px;
  left: 4px;
  width: 7px;
  height: 9px;
  opacity: 0;
  transform: rotate(45deg) scale(0);
  border-right: 2px solid #df919e;
  border-bottom: 2px solid #df919e;
  transition: all 0.3s ease;
  transition-delay: 0.15s;
}

#cbx:checked ~ .cbx {
  border-color: transparent;
  background: #df919e;
  animation: jelly 0.4s ease;
}

#cbx:checked ~ .cbx:after {
  opacity: 1;
  transform: rotate(45deg) scale(1);
}

체크된 상태에서 스타일 적용
.input[type="checkbox"]:checked + .cbx {
  border-color: transparent;
  background: #dee7ff;
  animation: jelly 0.4s ease;
}

.input[type="checkbox"]:checked + .cbx:after {
  opacity: 1;
  transform: rotate(45deg) scale(1);
}

.cntr {
  position: relative;
  display: inline-block;
  margin-right: 10px;
}

.hidden-xs-up {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
}


@keyframes jelly {
  from {
    transform: scale(1, 1);
  }

  20% {
    transform: scale(1.3, 0.7);
  }

  40% {
    transform: scale(0.7, 1.3);
  }

  60% {
    transform: scale(1.1, 0.9);
  }

  80% {
    transform: scale(0.9, 1.1);
  }

  to {
    transform: scale(1, 1);
  }
}

/* 라디오 버튼 스타일 */
.radio-input {
  display: flex;
  flex-direction: row;
  justify-content: center; /* 가운데 정렬 */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: white;
}

.radio-input input[type="radio"] {
  display: none;
}

.radio-input label {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #ccc;
  border-radius: 5px;
  margin-right: 12px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.radio-input label:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #ccc;
  transition: all 0.3s ease-in-out;
}

.radio-input input[type="radio"]:checked + label:before {
  background-color: white;
  border-color: #c9aaaf;
  top: 0;
}

.radio-input input[type="radio"]:checked + label {
  background-color: #c9aaaf;
  color: #333;
  animation: radio-translate 0.5s ease-in-out;
}

@keyframes radio-translate {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateX(0);
  }
}
