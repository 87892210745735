.reviews-container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  text-align: center; /* 전체 중앙 정렬 */
}

.average-rating {
  font-size: 1.2rem;
  margin-bottom: 20px;
  text-align: center;
  font-weight: bold;
}

.no-reviews {
  text-align: center;
  font-size: 1.1rem;
  color: #888;
}

.review-list-item {
  display: grid;
  width: 500px;
  grid-template-columns: 100px auto; /* 이미지와 리뷰 내용을 그리드로 배치 */
  align-items: start; /* 이미지와 텍스트를 시작 위치로 정렬 */
  border-bottom: 1px solid #ddd;
  padding: 20px 0;
  margin-bottom: 20px;
  text-align: left; /* 리뷰 내용 왼쪽 정렬 */
  height: 150px; /* 고정 높이 설정 */
}

.review-left {
  display: flex;
  justify-content: center;
}

.review-right {
  padding-left: 30px;
  overflow: hidden; /* 내용이 넘칠 경우 숨김 */
  height: 100%; /* 부모 요소와 동일한 높이로 설정 */
}

.review-header {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.review-user {
  font-size: 1.1rem;
  color: #888;
  margin-left: 10px;
}

.review-content {
  font-size: 1.1rem;
  color: #555;
  line-height: 1.5;
  max-height: 70%; /* 최대 높이 설정 */
  overflow: hidden; /* 내용이 넘칠 경우 숨김 */
}

.review-rating {
  font-size: 1.1rem;
  color: #555;
}

.review-image2 {
  width: 100px;
  height: 120px;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.pagination button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}
