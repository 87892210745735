/* 장바구니 전체 컨테이너 */
.cartContainer {
  width: 60%;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* 장바구니 헤더 (전체 선택, 삭제 버튼) */
.cartHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  margin-bottom: 10px;
  border-bottom: 2px solid #d3c2c2;
}

.cartHeader label {
  font-size: 16px;
}

.cartHeader input[type="button"] {
  font-size: 14px;
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
}

.cartHeader input[type="button"]:hover {
  color: red;
}

/* 장바구니 상품 아이템 */
.cartItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0; /* 여백 줄이기 */
  border-bottom: 1px solid #d3c2c2;
  width: 100%;
  min-height: 120px; /* 여백을 줄이기 위해 최소 높이도 줄임 */
}

/* 상품 이미지와 제품명 정렬 */
.cartItemContainer {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 50%; /* 이미지와 텍스트 영역의 총 비율 */
}

.cartItem img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 10px; /* 이미지와 텍스트 사이 마진 줄이기 */
}

/* 제품명 및 가격 영역 */
.cartItemDetails {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cartItemDetails span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; /* 너무 긴 텍스트는 생략(...) 처리 */
}

.cartItemPriceOriginal {
  text-decoration: line-through;
  color: #888;
  font-size: 14px;
  margin-bottom: 5px;
}

.cartItemPriceDiscounted {
  font-weight: bold;
  color: #444;
  font-size: 16px;
  margin-bottom: 10px;
}

/* 수량 조절 및 재고 문구 */
.cartQuantityWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100px;
}

.cartQuantity {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.stockWarning {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  text-align: center;
  min-height: 20px;
  line-height: 20px;
}

/* 가격 표시 영역 */
.cartItemPrice {
  flex-basis: 15%;
  text-align: right;
  font-weight: bold;
  font-size: 18px;
  color: #333;
}

/* 삭제 버튼 */
.rmButton {
  flex-basis: 5%;
  text-align: center;
  background: none;
  border: none;
  color: #000;
  font-size: 16px;
  cursor: pointer;
}

.rmButton:hover {
  color: red;
}

/* 장바구니 하단 (총 가격 표시, 주문 버튼) */
.cartFooter {
  width: 100%;
  text-align: right;
  padding-top: 20px;
}

.cartFooter .totalPrice {
  margin-right: 20px;
  font-size: 18px;
  font-weight: bold;
}

#originalPrice {
  font-size: 16px;
  color: #444;
  text-decoration: line-through;
}

#discountAmount {
  font-size: 18px;
  color: red;
}

#finalPrice {
  font-size: 20px;
  color: #000;
  font-weight: bold;
}

/* 수량 조절 버튼 */
.cartQuantity button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: 18px;
  padding: 0;
  margin: 0 5px;
  color: #000;
}

/* 수량 조절 버튼 마우스 오버 효과 */
.cartQuantity button:hover {
  color: #c7727e;
}

.errorMessage {
  color: red; /* 빨간색으로 에러 표시 */
  font-weight: bold; /* 에러 메시지를 강조 */
  margin-top: 10px; /* 버튼과의 간격 */
  text-align: center; /* 중앙 정렬 */
}


