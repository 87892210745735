/* Coupon.module.css */
.container {
  max-width: 800px;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.couponItem {
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  background-color: #fafafa;
  margin-bottom: 15px;
  text-align: center;
}

.couponName {
  font-weight: bold;
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}

.couponDiscount {
  font-size: 16px;
  color: #ff6f61;
  margin-bottom: 5px;
}

.couponExpiry {
  font-size: 14px;
  color: #999;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  background: none;
  border: 1px solid #dbdbdb;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  margin: 0 5px;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.pagination span {
  margin: 0 10px;
  font-size: 14px;
}
