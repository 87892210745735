.container {
  display: flex;
  gap: 20px;
}

.card {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th, .table td {
  padding: 10px;
  text-align: center;
  border: 1px solid #ddd;
}

.add-button {
  background-color: #df919e;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.add-button:hover {
  background-color: #c7677a;
}

#pagination {
  text-align: center;
  margin-top: 10px;
}
