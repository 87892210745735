/* PostWrite.module.css */

.container {
  max-width: 600px;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 20px;
  text-align: center;
}

.previewImage {
  width: 100px;
  height: 100px;
  object-fit: cover; /* 이미지의 중앙 부분을 유지하면서 잘라냄 */
  object-position: center; /* 이미지 중앙을 기준으로 위치 */
  border-radius: 5px; /* 선택 사항: 이미지 모서리를 둥글게 */
}

#photoBox {
  margin-bottom: 10px;
}

.buttonClass button {
  width: 20%;
}

.dropdownContainer {
  position: relative;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.3);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
  z-index: 3;
}

.dropdownItem {
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

.dropdownItem:hover {
  background-color: #f0f0f0;
}

.productInfoContainer {
  margin-top: 20px;
  padding-left: 15px;
  padding-bottom: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.productInfoDetails {
  display: flex;
  align-items: center;
}

.productInfoImage {
  width: 50px;
  height: 50px;
  margin-right: 15px;
  border-radius: 5px;
  object-fit: cover;
}

.productInfoName {
  font-size: 18px;
  font-weight: bold;
  margin-right: 15px;
}

.productInfoPrice {
  font-size: 16px;
  color: #888;
}

.submitButton {
  width: 100%;
  padding: 10px;
  background-color: #3897f0;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.submitButton:hover {
  background-color: #318ce7;
}

#error {
  color: red;
  margin-top: 10px;
}

.textareaContainer textarea {
  width: 96%;
}

.characterCount {
  font-size: 12px;
  color: #666;
  bottom: 10px;
  right: 10px;
}
