/* Posts.module.css */

.container {
  max-width: 600px;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.header {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #dbdbdb;
}

.userPic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.userInfo {
  font-weight: bold;
  font-size: 14px;
}

.postContent {
  padding: 15px;
}

.postImage {
  width: 100%;
  aspect-ratio: 1 / 1; /* 가로 세로 비율을 1:1로 설정 */
  object-fit: cover; /* 비율에 맞게 이미지 잘라내기 */
  border-radius: 10px; /* 선택 사항: 이미지 테두리를 둥글게 */
}

.page {
  width: 300px; /* 고정된 너비로 설정 */
  height: 300px; /* 고정된 높이로 설정 */
  object-fit: cover; /* 이미지 비율 유지하며 잘리도록 설정 */
  margin-bottom: 10px;
  border-radius: 10px; /* 선택 사항: 이미지 테두리를 둥글게 */
}

.actionButtons {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
}

.actionButton {
  background: none;
  border: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
}

.commentSection {
  padding: 15px;
}

.comment {
  margin-bottom: 10px;
}

.reply {
  margin-left: 20px;
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 5px;
}

textarea {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  resize: none;
}

.productInPost {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 10px;
  padding: 20px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.productInPost:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.topButtonBox {
  border: none;
  background-color: #dbdbdb;
  padding: 10px;
  border-radius: 20px;
  color: #333333;
  font-weight: bold;
}

.submitButton {
  margin-top: 10px;
  width: 100%;
  padding: 10px;
  background-color: #3897f0;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submitButton:hover {
  background-color: #318ce7;
}
