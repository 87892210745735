/* RegisterSuccess.module.css */
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #f9f9f9;
}

.icon {
  color: #ed0086;
  margin-bottom: 20px;
}

.heading {
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.buttonGroup {
  display: flex;
  gap: 10px;
}

.button {
  background-color: black !important;
  color: #ffffff !important;
  border: none !important;
  border-radius: 0px !important;
  padding: 10px 20px !important;
  font-size: 1rem !important;
  cursor: pointer !important;
  transition: background-color 0.3s ease !important;
}

.button:hover {
  background-color: darkgray !important;
}
