/* UserUpdate.module.css */
.body {
  text-align: center;
}

.container {
  text-align: center;
  max-width: 500px;
  margin: 40px auto 0 auto; /* 상단에 40px의 여백을 추가 */
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px 100px;
}

.user_input,
.email_input,
.address_input {
  margin-bottom: 15px;
  margin-right: 10px;
  /* display: flex; */
  /* flex-direction: column; */
}

.address_input {
  display: flex;
  align-items: center;
}

#userId {
  font-weight: bold;
  font-size: 120%;
  margin-bottom: 5px;
}

.modal {
  /* 모달 창의 내용 부분 */
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 500px;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  outline: none; /* 포커스 시 기본 윤곽선 제거 */
  text-align: center;
}

.overlay {
  /* 모달 창의 배경 부분 */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* 반투명 검정 배경 */
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttonn {
  width: 98%;
  margin-left: 4px !important;
}

/* 회원탈퇴 모달*/
.modal_buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.error {
  text-align: left;
  color: #ff6f61;
  font-size: 13px;
  margin-top: -3px;
  margin-bottom: 10px;
}
