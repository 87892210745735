.review-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 20px;
  gap: 30px;
}

.review-card {
  border: 1px solid #ddd;
  border-radius: 10px;
  width: 700px;
  background-color: white;
  overflow: hidden;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding-bottom: 15px;
}

.review-image {
  width: 100%;
  height: 700px;
  object-fit: cover;
}

.review-content {
  padding: 20px;
}

.review-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.user-nickname {
  font-weight: bold;
  font-size: 1.2rem;
  color: #333;
}

.stars {
  display: flex;
}

.review-text {
  margin-top: 15px;
  font-size: 1.1rem;
  color: #555;
  line-height: 1.6;
}

.product-link {
  display: block;
  margin-top: 20px;
  font-size: 1.1rem;
  text-decoration: none;
  color: #007bff;
  font-weight: bold;
}

.product-link:hover {
  text-decoration: underline;
}

/* 상품 정보 섹션 */
.product-info {
  display: flex;
  align-items: center;
  background-color: #c6c3bd; /* 연한 회색 배경 */
  padding: 10px;
  border-top: 1px solid #ddd;
}

.product-thumbnail {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
}

.product-details {
  display: flex;
  flex-direction: column;
}

.product-name {
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 5px;
}

.product-rating,
.product-reviews {
  font-size: 0.9rem;
  color: #888;
}
