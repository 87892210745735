.profileContainer {
  background-color: #f4f4f9;
  border-radius: 10px;
  padding: 20px;
  max-width: 300px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.profileActions {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profileActionLink,
.editButton {
  display: inline-block;
  padding: 10px 15px;
  margin: 10px 0;
  border-radius: 5px;
  text-decoration: none;
  text-align: center;
  width: 100%;
  max-width: 200px;
  transition: background-color 0.3s, transform 0.3s;
  cursor: pointer;
  font-size: 1em;
}

.profileActionLink {
  background-color: #007bff;
  color: white;
}

.profileActionLink:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.editButton {
  background-color: #6c757d;
  color: white;
  border: none;
}

.editButton:hover {
  background-color: #5a6268;
  transform: translateY(-2px);
}

.followInfo {
  font-size: 1em;
  color: #007bff;
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.followLink {
  text-decoration: none;
  color: #b39297;
}

.followLink:hover {
  text-decoration: underline;
}

/* 새로운 스타일링 */
.editInput {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 14px;
  background-color: #fff;
  color: #333;
}

.editInput:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.editInput::placeholder {
  color: #aaa;
}

.profilePic {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 15px;
}

.profileNickname,
.profileBio {
  margin-bottom: 10px;
  color: #333;
  font-size: 1em;
}

/* 오류 애니메이션 */
.error {
  border-color: red;
  animation: shake 0.5s;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-5px);
  }
  50% {
    transform: translateX(5px);
  }
  75% {
    transform: translateX(-5px);
  }
  100% {
    transform: translateX(0);
  }
}
